$primary: #691b99;
$primary-light: #9b4dcb;
$primary-dark: #37006a;
$secondary: rgba(0, 176, 255, 1);
$secondary-transparent: rgba(0, 176, 255, 0);
$secondary-light: #69e2ff;
$secondary-dark: #0081cb;
$text-primary: #fff;
$text-secondary: #000;
$background-grey: #e1e2e1;
$aws-color: #f90;
$docker-color: #0db7ed;
$white: #fff;


// Box shadow mixins
@mixin z1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24); // sass-lint:disable-line no-color-literals
}
@mixin z2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23); // sass-lint:disable-line no-color-literals
}
@mixin z3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 0 6px 6px rgba(0, 0, 0, .23); // sass-lint:disable-line no-color-literals
}
@mixin z4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22); // sass-lint:disable-line no-color-literals
}
@mixin z5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, .3), 0 15px 12px rgba(0, 0, 0, .22); // sass-lint:disable-line no-color-literals
}
@mixin z4-reverse {
  box-shadow: 0 -14px 28px rgba(0, 0, 0, .25), 0 -10px 10px rgba(0, 0, 0, .22); // sass-lint:disable-line no-color-literals
}

// Box shadow classes
.z1 {
  @include z1;
}

.z2 {
  @include z2;
}

.z3 {
  @include z3;
}

.z4 {
  @include z4;
}

.z5 {
  @include z5;
}

.z4-reverse {
  @include z4-reverse;
}

// Color classes
.color-primary {
  background-color: $primary !important;
  color           : $text-primary !important;
}

.color-primary-light {
  background-color: $primary-light !important;
  color           : $text-primary !important;
}

.color-primary-dark {
  background-color: $primary-dark !important;
  color           : $text-primary !important;
}

.color-secondary {
  background-color: $secondary !important;
  color           : $text-secondary !important;
}

.color-secondary-light {
  background-color: $secondary-light !important;
  color           : $text-secondary !important;
}

.color-secondary-dark {
  background-color: $secondary-dark !important;
  color           : $text-secondary !important;
}


body,
html {
  height    : 100%;
  margin    : 0;
  min-height: 100%;
}

body {
  background-color: $background-grey;
  display         : flex;
  flex-direction  : column;
  font-family     : 'Istok Web', sans-serif;
  // padding-bottom   : 60px;
}

.tab-content {
  flex: 1;
  padding-bottom: 60px;
}

.nav,
footer,
header {
  flex: none;
}


// header
.initials {
  font-family: 'Merienda One', cursive;
}

.title {
  font-family: 'Rubik', sans-serif;
}
@media all and (min-width: 465px) {
  .title {
    font-size: 70px;
  }
}
@media all and (max-width: 465px) and (min-width: 50px) {
  .title {
    font-size: 15vw;
  }
}


// nav
@media all and (max-width: 357px) and (min-width: 301px) {
  .nav-tabs {
    font-size: 4vw;
  }
}
@media all and (max-width: 300px) and (min-width: 257px) {
  .nav-tabs {
    font-size: 3.5vw;
  }
}
@media all and (max-width: 256px) and (min-width: 50px) {
  .nav-tabs {
    font-size: 3vw;
  }
}

.nav-item {
  font-family: 'Rubik', sans-serif;
}

.nav-link {
  border-bottom: 4px solid $secondary-transparent !important;
  border-left  : 0 !important;
  border-right : 0 !important;
  border-top   : 0 !important;
  transition   : border 500ms ease;

  &.active {
    border-bottom: 4px solid $secondary !important;
  }
}


// footer
.footer {
  height     : 60px;
  line-height: 60px;
}

.footer-name {
  font-family: 'Rubik', sans-serif;
}

// cards
.card-title {
  font-family: 'Rubik', sans-serif;
}

.card-subtitle {
  font-family: 'Rubik', sans-serif;
}

.card {
  flex: 1;
}

// Icon cloud
.aws {
  color: $aws-color;
}

.docker {
  color: $docker-color;
}

.tooltip-inner {
  @include z2;
  background-color: $primary-dark;
}

.tooltip {
  &.bs-tooltip-right {
    .arrow {
      &::before {
        border-right-color: $primary-dark !important;
      }
    }
  }

  &.bs-tooltip-left {
    .arrow {
      &::before {
        border-left-color: $primary-dark !important;
      }
    }
  }

  &.bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: $primary-dark !important;
      }
    }
  }

  &.bs-tooltip-top {
    .arrow {
      &::before {
        border-top-color: $primary-dark !important;
      }
    }
  }
}


// Other elements
button {
  @include z3;
  border    : 0 !important;
  outline   : none !important;
  transition: box-shadow .3s cubic-bezier(.25, .8, .25, 1);

  &:active {
    @include z1;
    outline   : none !important;
  }

  &:focus {
    border : 0 !important;
    outline: none !important;
  }
}

.alert {
  bottom  : 0;
  height  : 5em;
  left    : 0;
  margin  : auto;
  position: absolute;
  right   : 0;
  top     : 0;
  width   : 25em;
}


// Ripple effect
.ripple-effect {
  overflow : hidden;
  position : relative;
  transform: translate3d(0, 0, 0);
}

.ink {
  background       : $white;
  border-radius    : 50%;
  display          : block;
  opacity          : .1;
  pointer-events   : none;
  position         : absolute;
  transform        : scale(0);

  &.animate {
    animation        : ripple .5s linear;
  }
}

// Ripple animation effect
@keyframes ripple {
  100% {
    opacity  : 0;
    transform: scale(2.5);
  }
}
